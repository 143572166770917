<template>
  <div>
    <expansion-panels-layout>
      <!-- header -->
      <template v-slot:header>
        <record-details-banner :record="currentRecord">
          <document-link
            :record="currentRecord"
            v-on:click="showDocumentEditorDialog"
            :use-default-pdf-viewer="true"
            @openDefaultPdfViewer="openDefaultPdfViewerDialog"
          ></document-link>
        </record-details-banner>
      </template>

      <!-- content -->
      <template v-slot:content>
        <v-card flat>
          <!-- Record form -->
          <form-record
            ref="formRecord"
            :record="currentRecord"
            :categories="categories"
            :category="currentRecordCategory"
            :form-mode="currentRecordMode"
            :loadingCategory="loadingCategory"
            :loadingFieldValueId="loadingFieldValueId"
            :loadingDatabaseLookupData="loadingDatabaseLookupData"
            v-model="isValidFormRecord"
            @categoryChanged="onCategoryChanged"
            @moreText="onMoreText"
            @databaseLookup="onDatabaseLookup"
          ></form-record>

          <!-- Commands actions divider -->
          <v-divider v-show="isAllowedAuthor"></v-divider>

          <!-- Record's Commands -->
          <v-card-actions v-show="isAllowedAuthor">
            <!-- Edit Record (remarks: has to be base-button) -->
            <base-button
              v-show="visibleCommandEdit"
              :reactive-text="true"
              :disabled="!enableCommandEdit"
              @click="onRecordEdit"
            >
              {{ commandEditRecordLabel }}
              <template v-slot:icon-name>
                {{ recordOperationIcon(recordOperation.Update) }}
              </template>
            </base-button>

            <v-divider vertical v-if="visibleCommandAcquireRecord"></v-divider>

            <!-- record acquire -->
            <base-drop-down-menu-button
              top
              ref="refDropDownMenuAcquire"
              v-if="visibleCommandAcquireRecord"
            >
              <!-- Acquire -->
              <template v-slot:iconName>
                {{ iconAcquire }}
              </template>
              {{ commandAcquireLabel }}

              <!-- Acquire commands -->
              <template v-slot:commands>
                <!-- Acquire Scan -->
                <base-menu-item
                  :disabled="!enabledCommandScan"
                  v-show="visibleCommandScan"
                  @click="onAcquireScan"
                >
                  <template v-slot:iconName>
                    {{ iconScanner }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandAcquireScanLabel }}
                    </div>
                  </template>
                  {{ commandAcquireScanLabel }}
                </base-menu-item>

                <!-- Acquire -->
                <template v-if="visibleEditFileVersion">
                  <!-- Acquire - Edit checked out File Version -->
                  <base-menu-item
                    :disabled="!enabledCommandUploadFile"
                    @click="onEditFileVersion"
                  >
                    <template v-slot:iconName>
                      {{ iconUploadFile }}
                    </template>
                    <template v-slot:tooltipContent>
                      <div>
                        {{ uploadFileMenuLabel }}
                      </div>
                    </template>
                    {{ uploadFileMenuLabel }}
                  </base-menu-item>
                </template>

                <!-- Acquire - Upload File (using Popover Menu) -->
                <template v-else>
                  <import-file-popover-menu
                    v-model="visibleUploadFilePopoverMenu"
                    v-show="isAllowedOperation(recordOperation.Update)"
                    :disabled="!enabledCommandUploadFile"
                    :current-record="currentRecord"
                    :is-document-viewer="false"
                    :import-event="onUploadFile"
                    :progress="uploadFileProgress"
                    @close="visibleUploadFilePopoverMenu = false"
                  >
                  </import-file-popover-menu>
                </template>
              </template>
            </base-drop-down-menu-button>

            <v-divider
              vertical
              v-show="visibleCommandRecordActions"
            ></v-divider>

            <!-- Record Actions -->
            <base-drop-down-menu-button
              top
              ref="refDropDownMenuActions"
              v-if="visibleCommandRecordActions"
            >
              <template v-slot:iconName>
                {{ iconMore }}
              </template>
              <template v-slot:commands>
                <!-- Agenda Meeting Actions -->
                <base-menu-item
                  v-for="action in agendaMeetingActions"
                  :key="action.name"
                  :disabled="!enableAgendaMeetingCommandOperation(action.name)"
                  v-show="visibleAgendaMeetingCommandOperation(action.name)"
                  @click="onAgendaMeetingOperation(action.name)"
                >
                  <template v-slot:iconName>
                    {{ action.icon }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ action.description }}
                    </div>
                  </template>
                  {{ action.label }}
                </base-menu-item>

                <!-- Agenda Item Actions -->
                <base-menu-item
                  v-for="action in agendaItemActions"
                  :key="action.name"
                  :disabled="!enableAgendaItemCommandOperation(action.name)"
                  v-show="visibleAgendaItemCommandOperation(action.name)"
                  @click="onAgendaItemOperation(action.name)"
                >
                  <template v-slot:iconName>
                    {{ action.icon }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ action.description }}
                    </div>
                  </template>
                  {{ action.label }}
                </base-menu-item>

                <!-- Commit Refer/Defer -->
                <base-menu-item
                  v-show="isAllowedAuthor && isAllowedCommitReferDefer()"
                  :disabled="!enableCommitReferDefer()"
                  @click="onCommitReferDefer()"
                >
                  <template v-slot:iconName>
                    {{ iconPaste }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>Commit {{ isReferOrDefer() }}</div>
                  </template>
                  Commit {{ isReferOrDefer() }}
                </base-menu-item>

                <v-divider
                  v-show="isAllowedAuthor && visibleAgendaOperationDivider"
                ></v-divider>

                <!-- Record Source Control Actions -->
                <base-menu-item
                  v-for="action in sourceControlActions"
                  :key="action.name"
                  :disabled="!enableRecordCommandOperation(action.name)"
                  v-show="visibleRecordCommandOperation(action.name)"
                  @click="onRecordAction(action.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(action.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>{{ recordOperationTooltip(action.name) }}</div>
                  </template>
                  {{ recordOperationLabel(action.name) }}
                </base-menu-item>

                <!-- Check In File -->
                <base-menu-item
                  v-show="
                    visibleRecordCommandOperation(recordOperation.CheckIn)
                  "
                  :disabled="
                    !enableRecordCommandOperation(recordOperation.CheckIn)
                  "
                  @click="openCheckInDialog()"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(recordOperation.CheckIn) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(recordOperation.CheckIn) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(recordOperation.CheckIn) }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- record Attributes Operations -->
                <base-menu-item
                  v-for="action in recordAttributesOperations"
                  :key="action.name"
                  :disabled="!enableRecordCommandOperation(action.name)"
                  v-show="visibleRecordCommandOperation(action.name)"
                  @click="onRecordAction(action.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(action.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(action.name) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(action.name) }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- Re-Index -->
                <base-menu-item
                  v-show="
                    visibleRecordCommandOperation(recordOperation.Reindex)
                  "
                  :disabled="
                    !enableRecordCommandOperation(recordOperation.Reindex)
                  "
                  @click="onReindexAction()"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(recordOperation.Reindex) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(recordOperation.Reindex) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(recordOperation.Reindex) }}
                </base-menu-item>

                <!-- record Delete/Purge Operations -->
                <base-menu-item
                  v-for="operation in recordDeleteOperations"
                  :key="operation.name"
                  :disabled="!enableRecordCommandOperation(operation.name)"
                  :is-icon-danger="isRecordOperationDanger(operation.name)"
                  v-show="visibleRecordCommandOperation(operation.name)"
                  @click="onRecordAction(operation.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(operation.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ operation.tooltip }}
                    </div>
                  </template>
                  {{ operation.label }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- record Send Operations -->
                <base-menu-item
                  v-for="operation in recordSendOperations"
                  :key="operation.name"
                  :disabled="!enableRecordCommandOperation(operation.name)"
                  v-show="visibleRecordCommandOperation(operation.name)"
                  @click="onRecordAction(operation.name)"
                >
                  <template v-slot:iconName>
                    {{ recordOperationIcon(operation.name) }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ recordOperationTooltip(operation.name) }}
                    </div>
                  </template>
                  {{ recordOperationLabel(operation.name) }}
                </base-menu-item>

                <v-divider v-show="isAllowedAuthor"></v-divider>

                <!-- Select record in Folder -->
                <base-menu-item
                  :to="folderModuleRecordRouteLocation(record)"
                  :disabled="!canNavigateToFolderModuleRecord(record)"
                >
                  <template v-slot:iconName>
                    {{ commandSelectRecordIconName }}
                  </template>
                  <template v-slot:tooltipContent>
                    <div>
                      {{ commandSelectRecordLabel(record) }}
                    </div>
                  </template>
                  {{ commandSelectRecordLabel(record) }}
                </base-menu-item>
              </template>
            </base-drop-down-menu-button>

            <!-- Command Validate record data entry-->
            <base-tooltip-button
              v-if="visibleCommandValidate"
              @click="onValidateForm"
            >
              <template v-slot:icon-name>
                {{ iconValidate }}
              </template>
              <template v-slot:default v-if="visibleOnSmAndUp">
                {{ commandValidateFormLabel }}
              </template>
              <template v-slot:toolTip>
                {{ commandValidateFormToolTip }}
              </template>
            </base-tooltip-button>

            <!-- Create record, not applicable -->

            <!-- Save record -->
            <base-tooltip-button
              v-if="visibleCommandSaveRecord"
              :loading="isLoadingSaveRecord"
              :disabled="!isValidFormRecord"
              @click="onSaveRecord"
            >
              <template v-slot:icon-name>
                {{ iconSave }}
              </template>
              <template v-slot:default>
                {{ commandSaveRecordLabel }}
              </template>
              <template v-slot:toolTip>
                {{ commandSaveRecordToolTip }}
              </template>
            </base-tooltip-button>

            <!-- Cancel new/edit record mode-->
            <base-tooltip-button
              v-if="visibleCommandCancelRecordAction"
              @click="onCancelRecordAction"
            >
              <template v-slot:icon-name>
                {{ iconUndo }}
              </template>
              <template v-slot:default>
                {{ commandCancelRecordAction }}
              </template>
              <template v-slot:toolTip>
                {{ commandCancelRecordActionToolTip }}
              </template>
            </base-tooltip-button>
          </v-card-actions>
        </v-card>

        <!-- Download File Dialog -->
        <download-file-dialog
          :record="currentRecord"
          :visible="visibleDownloadFileDialog"
          :is-email="isEmail"
          @close="visibleDownloadFileDialog = false"
        >
        </download-file-dialog>

        <!-- Print File Dialog -->
        <print-file-dialog
          :record="currentRecord"
          :visible="visiblePrintFileDialog"
          @close="closePrintFileDialog"
        >
        </print-file-dialog>

        <!-- Check In Dialog -->
        <check-in-dialog
          :visible="visibleCheckInDialog"
          :record="currentRecord"
          :page-count="filePageCount"
          :check-in-event="checkInEditedFile"
          @close="visibleCheckInDialog = false"
        >
        </check-in-dialog>

        <!-- Release Dialog -->
        <release-dialog
          :visible="visibleDialogRelease"
          :record="currentRecord"
          :is-file-changed="isFileChanged"
          :release-event="recordRelease"
          @close="visibleDialogRelease = false"
        >
        </release-dialog>

        <!-- Document Editor Dialog -->
        <document-editor-dialog
          :id="editRecordId"
          :version="editRecordVersion"
          :visible="visibleDocumentEditorDialog"
          :record="currentRecord"
          :open-scan-dialog="openScanDialog"
          @update-record="onRecordChanged"
          @close="closeDocumentEditorDialog"
        ></document-editor-dialog>

        <!-- Document Editor Dialog -->
        <default-pdf-viewer-dialog
          :visible="visibleDefaultPdfViewerDialog"
          :record="currentRecord"
          @onCheckout="recordCheckOut(true)"
          @close="closeDefaultPdfViewerDialog"
        ></default-pdf-viewer-dialog>
      </template>
    </expansion-panels-layout>

    <!-- snackbar -->
    <snackbar
      v-model="snackbarVisible"
      @close="closeSnackbar"
      :color="snackbarColor"
      :top="snackbarTop"
      :timeout="snackbarTimeout"
      :multi-line="snackbarMultiLine"
      >{{ snackbarText }}</snackbar
    >
  </div>
</template>

<script>
// model
import { recordOperation } from "@/model/record/recordModel";

// services
import { isAllowedOperation } from "@/services/record/recordService";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  "project"
);
import {
  SET_RECORD_EDIT,
  SET_RECORD_MODE,
  SET_UPLOAD_FILE_PROGRESS,
  UPDATE_RECORD_INFO
} from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { recordDetailActionMixin } from "@/mixins/shared/record/recordDetailActionMixin";

export default {
  name: "ProjectRecordDetails",
  mixins: [recordDetailActionMixin],
  computed: {
    ...mapState({
      /**
       * Get current record
       * Overwrite computed recordDetailMixin.record()
       */
      record: "record",

      /**
       * Get current record file version
       * Overwrite computed recordDetailMixin.recordFileVersion()
       */
      recordFileVersion: "recordFileVersion",

      /**
       * Get versions
       */
      versions: "versions",

      /**
       * Current record Mode
       * Overwrite computed recordDetailMixin.currentRecordMode()
       */
      currentRecordMode: "recordMode",

      /**
       * Current record New
       * Overwrite computed recordDetailMixin.recordNew()
       */
      recordNew: "recordNew",

      /**
       * Current record New Category
       * Overwrite computed recordDetailActionMixin.recordNewCategory()
       */
      recordNewCategory: "recordNewCategory",

      /**
       * Get current record category
       * Overwrite computed recordDetailMixin.recordCategory()
       */
      recordCategory: "recordCategory",

      /**
       * Overwrite Get upload File Progress
       */
      uploadFileProgress: "uploadFileProgress"
    })
  },
  methods: {
    ...mapActions({
      /**
       * set Read Only Record
       * Overwrite method recordDetailActionMixin.setReadOnlyRecord()
       */
      setReadOnlyRecord: "setReadOnlyRecord",

      /**
       * lock Record
       * Overwrite method recordDetailActionMixin.lockRecord()
       */
      lockRecord: "lockRecord",

      /**
       * unLock Record
       * Overwrite method recordDetailActionMixin.unLockRecord()
       */
      unLockRecord: "unLockRecord",

      /**
       * set Draft Record
       * Overwrite method recordDetailActionMixin.setDraftRecord()
       */
      setDraftRecord: "setDraftRecord",

      /**
       * Check Out File
       * Overwrite recordDetailActionMixin.checkOutFile(id)
       */
      checkOutFile: "checkOutFile",

      /**
       * Check Out the File as a PDF document, so that e-signature can be applied locally at the client station
       * Overwrite recordDetailActionMixin.checkOutFileForSign(id)
       */
      checkOutFileForSign: "checkOutFileForSign",

      /**
       * Release File
       * Overwrite recordDetailActionMixin.releaseFile(id)
       */
      releaseFile: "releaseFile",

      /**
       * Check In File
       * Overwrite recordDetailActionMixin.checkInFile(payload)
       */
      checkInFile: "checkInFile",

      /**
       * uploadFile
       */
      uploadFile: "uploadFile",

      /**
       * upload Compound Document File
       * Overwrite fileCheckInMixin.uploadCompoundDocumentFile(payload)
       * @param {{id: Number, file: any, insertBeforePage: Number, enqueue: Boolean, comments: string}} payload
       * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
       */
      uploadCompoundDocumentFile: "uploadCompoundDocumentFile",

      /**
       * save Record
       * Overwrite recordDetailActionMixin.saveRecord(payload)
       */
      saveRecord: "saveRecord",

      /**
       * set New Record Category
       * Overwrite recordDetailActionMixin.setNewRecordCategory(id)
       */
      setNewRecordCategory: "setNewRecordCategory",

      /**
       * set current Record Text Field Value
       * Overwrite recordDetailActionMixin.setRecordTextFieldValue(id)
       */
      setRecordTextFieldValue: "setRecordTextFieldValue",

      /**
       * set Database Lookup Data
       * Overwrite recordDetailActionMixin.setDatabaseLookupData(id)
       */
      setDatabaseLookupData: "setDatabaseLookupData",

      /**
       * Overwrite Abstract method: recordDetailActionMixin.checkInScannedFile()
       * which check In Scanned File
       * @param {{insert: boolean, insertAtBeginning: boolean}|undefined} payload
       */
      checkInScannedFile: "checkInScannedFile",

      /**
       * Overwrite Abstract method: getRecordInfo
       * @param {id:number} id
       * @return record
       */
      getRecordInfo: "getRecordInfo",

      /**
       * Overwrite Abstract method: recordDetailActionMixin.heckInEditedDocument()
       * which check In an edited record file version
       * @param {{id:number, version:number, extension:string, comment:string, ocr:boolean, draft:boolean }} payload
       * @return {Promise<{id: number, name: string, categoryId: number, children: number, createdBy: string, creationDate: string, extension: string, flags: number, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, isReadOnly: boolean, modificationDate: string, owner: string, pageCount: number, parentId: number, recordType: string, recordTypeId: number, state: string, stateId: number, version: number, versionDate: string, versionOwner: string}>}
       */
      checkInEditedDocument: "checkInEditedDocument",

      /**
       * Overwrite Abstract method: setFileVersions
       * @param {id:number} id
       * @return record
       */
      setFileVersions: "setFileVersions",

      /**
       * post Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.postAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      postAgendaMeeting: "postAgendaMeeting",

      /**
       * un post Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.unPostAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      unPostAgendaMeeting: "unPostAgendaMeeting",

      /**
       * publish Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.publishAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      publishAgendaMeeting: "publishAgendaMeeting",

      /**
       * un publish Agenda Meeting
       * Overwrite Abstract method recordDetailActionMixin.publishAgendaMeeting
       * @param {id:number} id Agenda Meeting id
       * @return record
       */
      unPublishAgendaMeeting: "unPublishAgendaMeeting",

      /**
       * submit Agenda Item
       * Overwrite Abstract method recordDetailActionMixin.submitAgendaItem
       * @param {id:number} id Agenda Item id
       * @return record
       */
      submitAgendaItem: "submitAgendaItem",

      /**
       * recall Agenda Item
       * Overwrite Abstract method recordDetailActionMixin.recallAgendaItem
       * @param {id:number} id Agenda Item id
       * @return record
       */
      recallAgendaItem: "recallAgendaItem",

      /**
       * create Agenda
       */
      createAgenda: "createAgenda",

      /**
       * create Agenda Packet
       */
      createAgendaPacket: "createAgendaPacket"
    }),
    ...mapMutations({
      setUploadFileProgress: SET_UPLOAD_FILE_PROGRESS,
      setRecordMode: SET_RECORD_MODE,
      setEditRecord: SET_RECORD_EDIT,
      updateRecordInfo: UPDATE_RECORD_INFO
    }),

    /**
     * Overwrite recordDetailActionMixin.closeUploadFileDropdownParentMenu
     */
    closeUploadFileDropdownParentMenu() {
      this.$refs.refDropDownMenuAcquire?.closeMenu();
    },

    /**
     * Overwrite recordDetailActionMixin.closeCheckInFileDropdownParentMenu
     */
    closeCheckInFileDropdownParentMenu() {
      this.$refs.refDropDownMenuActions?.closeMenu();
    },

    /**
     * Overwrite recordDetailMixin.isAllowedOperation,
     * since recordOperation.Delete would not be applicable for Project's Record
     * @param operation
     * @return {boolean|Boolean}
     */
    isAllowedOperation(operation) {
      if (operation === recordOperation.Delete) {
        return false;
      }

      return isAllowedOperation(this.record, operation);
    }
  }
};
</script>
